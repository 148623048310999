import { useState } from "react";
import { Box, Menu, MenuItem, ListItemIcon, Avatar, Typography, ListItemText } from "@mui/material";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { authServices } from "../../services/authService";
import { setUserDetails } from "../../Redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Redux/reducers/toastSlice";
import ChangePassword from "../Login/ChangePassword";

const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user_details);

  const [anchorEl, setAnchorEl] = useState(null);
  const [ShowChangePassword, setShowChangePassword] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const logOut = async () => {
    try {
      const reqData = { UserId: userDetails?.UserId, SessionId: userDetails?.SessionId };
      await authServices.logout(reqData);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      localStorage.removeItem("swaggerUserSession");
      dispatch(setUserDetails(null));
      navigate("/login");
    }
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{ gap: 0.5, cursor: "pointer" }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="v-center">
        <Avatar alt={userDetails?.name} sx={{ width: 26, height: 26, fontSize: "12px" }}>
          {`${userDetails.name.split(" ")[0][0]}${userDetails.name.split(" ")?.at(-1)[0]}`}
        </Avatar>
        <Typography variant="bold">{userDetails?.name?.split(" ")?.[0]}</Typography>
        <KeyboardArrowDownRoundedIcon fontSize="small" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 0 },
          "& .MuiListItemIcon-root": { minWidth: 32 },
          "&:before": {
            // eslint-disable-next-line quotes
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {/* <MenuItem onClick={() => setShowChangePassword(true)}>
          <ListItemIcon>
            <PasswordOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{"Change Password"}</ListItemText>
        </MenuItem> */}

        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{"Logout"}</ListItemText>
        </MenuItem>
      </Menu>
      {ShowChangePassword && <ChangePassword handleClose={() => setShowChangePassword(false)} />}
    </>
  );
};

export default AccountSettings;
