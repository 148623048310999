// Environment Variables
const loginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL;
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const licenseManagementUrl = process.env.REACT_APP_LICENSE_MANAGEMENT_URL;
const authUrl = process.env.REACT_APP_AUTH_URL;
const engineUrl = process.env.REACT_APP_ENGINE_URL;
const keyManagerUrl = process.env.REACT_APP_KEY_MANAGER_URL;
const fileStorageUrl = process.env.REACT_APP_STORAGE_SERVICE;
const kitapBotUrl = process.env.REACT_APP_KITAP_BOT_URL;

// Derived Values
const chatBotApiUrl = `${engineUrl}/api/Copilot/AskQuery`;

// Configuration Flags
const azureLoginEnabled = true;
const azureAppInsightsEnabled = false;

// Session Configuration
const sessionTimeoutMinutes = Number.parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES, 10) || 300;
const idealTimeoutMs = sessionTimeoutMinutes * 60 * 1000;

// Exported Configurations
export {
  loginRedirectUrl,
  apiServerUrl,
  licenseManagementUrl,
  authUrl,
  engineUrl,
  keyManagerUrl,
  chatBotApiUrl,
  azureLoginEnabled,
  azureAppInsightsEnabled,
  idealTimeoutMs,
  fileStorageUrl,
  kitapBotUrl,
};
