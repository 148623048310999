import { tokens } from "./tokens";
import InnerHeaderBg from "../images/InnerHeader_Bg.png";

const themeSettings = (mode) => {
  const colors = tokens(mode);
  const { gray, primary, white, heading, border, darktext, cardbg } = colors;

  const getSidemenuStyles = () => ({
    "& .sidemenuCus": {
      "& a": {
        color: darktext,
        "& span.MuiTypography-root": { fontWeight: "300" },
        "& .MuiListItemIcon-root": { minWidth: "40px !important" },
      },
      "& img": { height: 36 },
    },
  });

  const palette = {
    mode,
    background: { default: primary[500], paper: mode === "dark" ? primary[500] : "white" },
  };

  const typography = {
    allVariants: { fontSize: 12 },
    h5: { fontSize: 18, fontWeight: 600 },
    h6: { fontSize: 14, fontWeight: 600 },
    bold: { fontWeight: 600 },
    sectionHeading: { color: heading, fontSize: "14px", fontWeight: 600, textTransform: "uppercase" },
    detailTitle: { color: gray[700], fontSize: "14px", fontWeight: 600, textTransform: "capitalize" },
    detailValue: { color: gray[700], fontSize: "12px", fontWeight: 500 },
  };

  const components = {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#71787e",
          fontFamily: "Poppins, Helvetica, 'sans-serif' ",
          "& .flex": { display: "flex" },
          "& .relative": { position: "relative" },
          "& .absolute": { position: "absolute" },
          "& .column": { flexDirection: "column" },
          "& .row": { flexDirection: "row" },
          "& .flex-end": { display: "flex", alignItems: "flex-end" },
          "& .display-inline": { display: "inline-flex" },
          "& .center": { display: "flex", alignItems: "center", justifyContent: "center" },
          "& .v-center": { display: "flex", alignItems: "center" },
          "& .h-center ": { display: "flex", justifyContent: "center" },
          "& .space-between": { display: "flex", justifyContent: "space-between", alignItems: "center" },
          "& .space-around": { display: "flex", justifyContent: "space-around" },
          "& .float-right": { float: "right" },
          "& .upper-case": { textTransform: "uppercase" },
          "& .bold-text": { fontWeight: 600 },
          "& .MuiPaper-root": { boxShadow: "none" },
          "& .MuiPaper-root.MuiPaper-elevation0": { boxShadow: "none" },
          "& .innerHeader": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: 2,
            boxShadow: "0px 4px 8px 0px rgba(46, 45, 125, 0.16)",
            background: `url(${InnerHeaderBg})`,
            backgroundSize: "cover",
            color: "#F9F9FF",
            padding: "12px 16px 12px 16px",
            boxSizing: "border-box",
          },
          "& .innerHeaderContainer": {
            position: "fixed",
            zIndex: 2,
            marginLeft: "-16px",
            marginRight: "-16px",
            borderRadius: 0,
            transition: "width 0.3s ease-in-out, max-width 0.3s ease-in-out",
          },
          "& .pt74": {
            paddingTop: "74px",
            width: "100%",
          },

          "& .container": {
            borderRadius: "12px",
            border: `1px solid ${border}`,
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
            width: "100%",
            "& .subcontainer": {
              background: cardbg,
              textAlign: "center",
              color: darktext,
            },
          },

          "& .outlet": {
            padding: "0px 16px 16px",
            borderRadius: "16px 16px 0 0",
            width: "100%",
          },
          ...getSidemenuStyles(),
        },
      },
    },
  };

  return { palette, typography, components };
};

export default themeSettings;
