import CryptoJS from "crypto-js";
import shajs from "sha.js";

const DECRYPTION_KEY = "AecECroRUgnGTa";
const IV = CryptoJS.enc.Utf8.parse("globalaesvectors");

/**
 * Generates a key from the decryption key using SHA-256.
 * @returns {CryptoJS.lib.WordArray} - The generated key.
 */
const generateKey = () => {
  const hashString = shajs("sha256").update(DECRYPTION_KEY).digest("hex");
  return CryptoJS.enc.Utf8.parse(hashString.substring(0, 32));
};

/**
 * Decrypts an encrypted string using AES encryption.
 * @param {string} encryptedString - The encrypted string to decrypt.
 * @returns {object|null} - The decrypted object or null if decryption fails.
 */
export const decryptData = (encryptedString) => {
  if (!encryptedString) {
    console.error("Encrypted string is undefined or null");
    return null;
  }

  try {
    const key = generateKey();

    // Prepare the encrypted string for decryption
    const formattedString = encryptedString.replace(/-/g, "+").replace(/_/g, "/");
    const decrypted = CryptoJS.AES.decrypt(formattedString, key, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedUtf8 = decrypted.toString(CryptoJS.enc.Utf8);

    // Check if the decrypted output is valid JSON
    try {
      return JSON.parse(decryptedUtf8);
    } catch (jsonError) {
      console.error("Decryption succeeded but JSON parsing failed:", jsonError);
      return null; // Return null if JSON parsing fails
    }
  } catch (error) {
    console.error("Error during decryption:", error);
    return null; // Return null if an error occurs during decryption
  }
};

/**
 * Encrypts a given string using AES encryption.
 *
 * @param {string} inputString - The string to encrypt.
 * @returns {Object} An object containing the encrypted data in Base64 format.
 * @throws Will throw an error if the input string is null or undefined or if encryption fails.
 */
export const encryptData = (inputString) => {
  if (!inputString) {
    console.error("Input string is undefined or null");
    throw new Error("Input string cannot be null or undefined");
  }

  try {
    const key = generateKey();

    // Encrypt the string using AES
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputString), key, {
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert the encrypted data to Base64 format
    let encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    encryptedBase64 = encryptedBase64.replace(/\+/g, "-").replace(/\//g, "_");

    return { encryptedData: encryptedBase64 };
  } catch (error) {
    console.error("Error during encryption:", error);
    throw error; // Rethrow the error after logging it
  }
};
