import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import Logo from "../../images/kairos_logo.png";
import LoginBg from "../../images/user-manual.png";
import ConfirmModel from "./ConfirmModel";
import { PublicClientApplication } from "@azure/msal-browser";
import ADLoginLoading from "./ADLoginLoading";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";
import { azureLoginEnabled, loginRedirectUrl } from "../../utils/constants";
import { authServices } from "../../services/authService";
import { setUserDetails } from "../../Redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { decryptData, encryptData } from "../../utils/encryptionUtils";
import { setSecretKey } from "../../utils/helpers";
import { showToast } from "../../Redux/reducers/toastSlice";

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright"} ©
      <a href="https://kairostech.com/" target="_blank" rel="noopener noreferrer">
        {"Kairos Technologies"}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const handleLoginError = async (error, dispatch) => {
  const dc = typeof error?.response?.data === "string" && (await decryptData(error?.response?.data));
  const errMessage =
    dc?.Message ||
    dc?.statusText ||
    (error.message.includes("Network Error") && "AuthNZ Server is currently down! Please contact IT support.");

  dispatch(showToast({ message: errMessage, type: "error" }));
};

const fetchKeys = async () => {
  const data = await setSecretKey(["DQG-AppClientId", "DQG-AppTenantId"]);
  const azureClientId = data?.find((item) => item.SecretKey === "DQG-AppClientId")?.SecretValue;
  const azureTenentId = data?.find((item) => item.SecretKey === "DQG-AppTenantId")?.SecretValue;
  return { azureClientId, azureTenentId };
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.auth.user_details);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [loadingADLogin, setLoadingADLogin] = useState(false);
  const [userData, setUserData] = useState({});

  const PwIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  const handleShowPassword = () => setShowPassword(!showPassword);

  const onSubmitLogin = async (data) => {
    setLoading(true);
    try {
      const response = await authServices.login(data.email, data.password);
      if (response?.ResponseObject?.IsSessionExists) {
        const requestBody = data;
        setUserData({ requestBody, response: response?.ResponseObject });
        setConfirmModel(true);
      } else if (response?.ResponseObject?.Token) {
        localStorage.setItem(
          "swaggerUserSession",
          JSON.stringify(encryptData(JSON.stringify(response.ResponseObject))?.encryptedData),
        );
        dispatch(setUserDetails(response.ResponseObject));
        setLoadingADLogin(false);
        navigate("/");
      }
    } catch (error) {
      setLoadingADLogin(false);
      handleLoginError(error, dispatch);
    }
    setLoading(false);
  };

  const Confirm = async (data) => {
    setConfirmModel(false);
    if (data.logedInHere) {
      try {
        const reqData = {
          UserId: userData?.response?.UserId,
          SessionId: userData?.response?.SessionId,
        };
        const response = await authServices.logout(reqData);
        if (response?.ApiStatusCode === 200) {
          await onSubmitLogin(userData?.requestBody);
        }
      } catch (error) {
        handleLoginError(error, dispatch);
      }
    } else {
      setLoadingADLogin(false);
      setUserData({});
    }
  };

  const handleLoginButtonClick = async () => {
    setLoading(true);
    try {
      const { azureClientId, azureTenentId } = await fetchKeys();
      const msalConfig = {
        auth: {
          clientId: azureClientId,
          authority: `https://login.microsoftonline.com/${azureTenentId}`,
          redirectUri: `${loginRedirectUrl}login`,
        },
      };

      const pca = new PublicClientApplication(msalConfig);
      await pca.initialize();
      await pca.loginRedirect({ scopes: ["User.Read"] });
    } catch {
      dispatch(showToast({ message: "AD_LOGIN_ERROR", type: "error" }));
    }
    setLoading(false);
  };

  useLayoutEffect(() => {
    const handleRedirect = async () => {
      setLoadingADLogin(true);
      try {
        const { azureClientId, azureTenentId } = await fetchKeys();
        const msalConfig = {
          auth: {
            clientId: azureClientId,
            authority: `https://login.microsoftonline.com/${azureTenentId}`,
            redirectUri: `${loginRedirectUrl}login`,
          },
        };

        const pca = new PublicClientApplication(msalConfig);
        await pca.initialize();
        const response = await pca.handleRedirectPromise();
        if (response) {
          localStorage.setItem(
            "swaggerUserSession",
            JSON.stringify(encryptData(JSON.stringify(response.account))?.encryptedData),
          );
          dispatch(setUserDetails(response.account));
          setLoadingADLogin(false);
          navigate("/");
        }
      } catch {
        dispatch(showToast({ message: "AD_LOGIN_ERROR", type: "error" }));
        setLoadingADLogin(false);
      }
      setLoading(false);
    };

    if (window.location.hash) {
      handleRedirect();
    }
  }, []);

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {loadingADLogin ? (
        <ADLoginLoading loading={loading} />
      ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            md={7}
            lg={8}
            sx={{ backgroundColor: (b) => (b.palette.mode === "light" ? b.palette.grey[50] : b.palette.grey[900]) }}
            height="100%">
            <Box
              sx={{
                backgroundImage: `url(${LoginBg})`,
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>
          <Grid item md={5} lg={4} sx={{ boxShadow: 5 }}>
            <Paper className="flex column" sx={{ height: "100%", py: 4, justifyContent: "space-around" }}>
              <Box className="center column" sx={{ px: 4, gap: 2 }}>
                <img src={Logo} width="150" alt={"logo-notavailable"} />
                <Typography component="h1" variant="h5" sx={{ mt: "15px" }}>
                  {"Sign In"}
                </Typography>
                {!azureLoginEnabled ? (
                  <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmitLogin)}>
                    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={"Email Address"}
                        name="email"
                        autoComplete="email"
                        size="small"
                        autoFocus
                        error={errors.email}
                        {...register("email", { required: true })}
                      />

                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label={"Password"}
                        variant="outlined"
                        size="small"
                        error={errors.password}
                        {...register("password", { required: true })}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword}>{PwIcon}</IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomLoadingButton
                        type="submit"
                        sx={{ mt: 3, mb: 2, mx: "auto" }}
                        disabled={loading}
                        loading={loading}>
                        {"Sign In"}
                      </CustomLoadingButton>
                    </Box>
                  </form>
                ) : (
                  <CustomLoadingButton
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                    loading={loading}
                    onClick={handleLoginButtonClick}>
                    Login with Azure AD
                  </CustomLoadingButton>
                )}
              </Box>
              <Copyright sx={{ mt: "auto" }} />
            </Paper>
          </Grid>
        </Grid>
      )}
      {confirmModel && (
        <ConfirmModel confirmModel={confirmModel} setConfirmModel={setConfirmModel} returnValue={Confirm} />
      )}
    </>
  );
};

export default SignIn;
