import { authServices } from "../services/authService";

export const setSecretKey = async (encryptArray) => {
  try {
    const encryptKeyObj = {
      secrets: encryptArray?.map((each) => ({
        secretKey: each,
      })),
    };
    const res = await authServices.getDecryptionkey(encryptKeyObj);
    return res?.data?.ResponseObject?.Secrets;
  } catch (e) {
    console.log(e);
    return null;
  }
};
