import axios from "axios";
import { setUserDetails } from "../Redux/reducers/authSlice";
import { decryptData, encryptData } from "../utils/encryptionUtils";
import { showToast } from "../Redux/reducers/toastSlice";
import { authServices } from "../services/authService";

export const setupAxiosInterceptors = async (userDetails, navigate, dispatch, setSnack) => {
  if (!userDetails?.Token) {
    delete axios.defaults.headers.common.Authorization;
    axios.interceptors.request.eject(axios.interceptors.request.handlers[0]);
    axios.interceptors.response.eject(axios.interceptors.response.handlers[0]);

    return;
  }

  const { Token, UserId, SessionId } = userDetails;
  let isLogoutInProgress = false;

  axios.defaults.headers.common.Authorization = `Bearer ${Token}`;

  const handleRequestSuccess = (config) => config;

  const handleRequestError = (error) => Promise.reject(error);

  const handleResponseSuccess = (response) => response;

  const handleResponseError = async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401) {
      if (originalRequest._retry) {
        return Promise.reject(error);
      }

      if (error?.response?.data?.error === "jwtExpired") {
        originalRequest._retry = true;

        try {
          const tokenData = { token: Token };
          const refreshTokenResponse = await authServices.getRefreshToken(tokenData);
          const newToken = refreshTokenResponse?.data?.ResponseObject?.Token;

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          const updatedUserDetails = { ...userDetails, Token: newToken };

          axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          localStorage.setItem(
            "swaggerUserSession",
            JSON.stringify(encryptData(JSON.stringify(updatedUserDetails))?.encryptedData),
          );
          dispatch(setUserDetails(updatedUserDetails));

          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      if (!isLogoutInProgress) {
        isLogoutInProgress = true;

        try {
          await authServices.logout({ UserId, SessionId });
          localStorage.removeItem("swaggerUserSession");
          dispatch(setUserDetails(null));

          navigate("/login");
        } catch (logoutError) {
          dispatch(showToast({ message: logoutError?.response?.data?.message || logoutError.message, type: "error" }));
        }
      }
    }

    if (error?.response?.status === 402 && !isLogoutInProgress) {
      isLogoutInProgress = true;

      try {
        const decryptedData = await decryptData(error.response.data);
        navigate(`/license/${decryptedData?.ExpiryType}`);
      } catch (decryptionError) {
        setSnack({
          message: decryptionError?.response?.data?.message || decryptionError.message,
          colour: "error",
          open: true,
        });
      }
    }

    return Promise.reject(error);
  };

  axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
  axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
};
