import { Snackbar } from "@mui/material";
import { forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlert = () => {
  // const { type, message } = useSelector((state) => state.toast);
  const handleClose = () => {
    // dispatch(hideToast());
  };

  const isAlertOpen = false;
  const type = "";
  const message = "";
  return (
    <>
      {isAlertOpen && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isAlertOpen}
          autoHideDuration={3000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={type || undefined} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CustomAlert;
