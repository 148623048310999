import { useState, Suspense, useMemo, useLayoutEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./App.css";
import { setupAxiosInterceptors } from "./services/axios-interceptor";
// import "./i18n";

import SessionTimeout from "./utils/SessionTimeout";
import { idealTimeoutMs } from "./utils/constants";
import { ColorModeContext, useMode } from "./theme/theme";
import RoutesManager from "./RoutesManager";
import { useDispatch, useSelector } from "react-redux";
import { SideBarContext } from "k-labs-kit";

import CustomAlert from "./components/CustomComponents/CustomAlert";

import { setUserDetails } from "./Redux/reducers/authSlice";
import { decryptData } from "./utils/encryptionUtils";
import { showToast } from "./Redux/reducers/toastSlice";
import { authServices } from "./services/authService";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const userDetails = useSelector((state) => state.auth.user_details);
  const [drawerWidth, setDrawerWidth] = useState(200);

  const contextValue = useMemo(() => ({ setDrawerWidth, drawerWidth }), [setDrawerWidth, drawerWidth]);
  const token = userDetails?.idToken || "";

  useLayoutEffect(() => {
    try {
      const encryptedUser = localStorage.getItem("swaggerUserSession");
      const userData = encryptedUser ? decryptData(JSON.parse(encryptedUser)) : null;

      if (userData && !token) {
        dispatch(setUserDetails(userData));
      }

      setupAxiosInterceptors(userData, navigate, dispatch);
    } catch (error) {
      console.error("Error during user data processing:", error);
    }
  }, [token]);

  const handleLogout = async () => {
    try {
      await authServices.logout(userDetails);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    localStorage.removeItem("swaggerUserSession");
    dispatch(setUserDetails(null));
    navigate("/login");
  };

  const handleSessionTimeout = () => {
    try {
      if (userDetails?.SessionId) {
        // setSnack({ message: t("error_msg_session_expired_message"), open: true, color: "error" });
        handleLogout();
      }
    } catch {
      // setSnack({ message: e?.response?.data?.message ?? e.message, open: true, color: "error" });
    }
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={null}>
          <SideBarContext.Provider value={contextValue}>
            <RoutesManager />
          </SideBarContext.Provider>
        </Suspense>

        {token && <SessionTimeout timeout={idealTimeoutMs} onTimeout={handleSessionTimeout} />}
        <CustomAlert />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
