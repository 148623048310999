import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const formatDateLocale = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};

export const filterObject = (obj, callback) => {
  if (!obj) {
    return {};
  } // Check if obj is null or undefined
  return Object.keys(obj)
    .filter((key) => callback(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
};

export const getDaysDiff = (expiryDate) => {
  if (!expiryDate) {
    return "--";
  }

  const today = dayjs();
  const expiryDayJs = dayjs(expiryDate);
  const duration = dayjs.duration(expiryDayJs.diff(today));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let result = "";

  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""} `;
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? "s" : ""} `;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? "s" : ""}`;
  }

  return result.trim();
};

export const formatDateTime = (date) => {
  return dayjs(date || new Date()).format("DD-MM-YYYY hh:mm A");
};

export const originUrl = "https://kairosfilestorage1.blob.core.windows.net/api-docs";
