import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"; // Import the icon
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import EditNotificationsOutlinedIcon from "@mui/icons-material/EditNotificationsOutlined";
import SignIn from "./components/Login/";
import { Header, SideMenu } from "k-labs-kit";
import "k-labs-kit/dist/style.css";
import darkLogo from "../src/images/kairos_logo.png";
import lightLogo from "../src/images/kairos_logo_white.png";

import { ColorModeContext } from "./theme/theme";
import AccountSettings from "./components/Layout/AccountSettings";
import AuthNZ from "./Pages/Auth-NZ";
import { useSelector } from "react-redux";
import ExpiryNotification from "./components/Layout/ExpiryNotification";
import LicenseManagement from "./Pages/License-Management";
import KeyManager from "./Pages/Key-Manager";
import FileManager from "./Pages/File-Manager";
import Schedular from "./Pages/Schedular";
import Notifications from "./Pages/Notifications";

const LayoutComponent = () => {
  const colorMode = useContext(ColorModeContext);

  const menuItems = [
    { to: "/", text: "Auth NZ", Icon: SecurityOutlinedIcon, status: true },
    { to: "/key-manager", text: "Key Manager", Icon: VpnKeyOutlinedIcon, status: true },
    { to: "/file-manager", text: "File Manager", Icon: FolderOutlinedIcon, status: true },
    { to: "/schedular", text: "Schedular", Icon: AccessAlarmOutlinedIcon, status: true },
    { to: "/notifications", text: "Notifications", Icon: EditNotificationsOutlinedIcon, status: true },
    { to: "/license-management", text: "License Management", Icon: AssignmentOutlinedIcon, status: true },
  ];

  return (
    <>
      <Header
        colorMode={colorMode}
        accountSettings={<AccountSettings />}
        title={
          <Typography variant="h5" fontSize={26}>
            Kairos - API Documentation
          </Typography>
        }>
        <ExpiryNotification />
        {/* <ServiceStatusPopup /> */}
      </Header>
      <SideMenu
        menuItems={menuItems}
        lightLogo={lightLogo}
        darkLogo={darkLogo}
        logoIcon={"https://klabs.kairostech.com/favicon.ico"}
      />
    </>
  );
};

const routesData = [
  { path: "/", element: <AuthNZ /> },
  { path: "/license-management", element: <LicenseManagement /> },
  { path: "/key-manager", element: <KeyManager /> },
  { path: "/file-manager", element: <FileManager /> },
  { path: "/schedular", element: <Schedular /> },
  { path: "/notifications", element: <Notifications /> },
];

const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.user_details);
  return token ? children : <Navigate to="/login" />;
};

const SidebarLayout = () => {
  const token = useSelector((state) => state.auth.user_details);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "52px",
        "& .headerIcons": { "& hr": { display: "none" }, "& .MuiIconButton-root": { display: "none" } },
      }}>
      <LayoutComponent />
      <Box className="outlet pt74">
        <Outlet />
      </Box>
    </Box>
  );
};

const RoutesManager = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route element={<SidebarLayout />}>
        {routesData.map(({ path, element }, index) => (
          <Route key={index} path={path} element={<PrivateRoute>{element}</PrivateRoute>} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default RoutesManager;
