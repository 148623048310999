import {
  Box,
  Chip,
  // Typography, Button, Popover, Paper
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { styled } from "@mui/system";

// const Arrow = styled("div")(({ theme }) => ({
//   width: 0,
//   height: 0,
//   borderLeft: "10px solid transparent",
//   borderRight: "10px solid transparent",
//   borderBottom: `10px solid ${theme.palette.background.paper}`,
//   position: "absolute",
//   top: -10,
//   left: "calc(50% - 10px)",
// }));

const ExpiryNotification = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);

  const [color, setColor] = useState("#ffbb29");
  const [daysRemaining, setDaysRemaining] = useState(null);
  //   const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (userDetails?.expiryDate) {
      const expiryDate = new Date(userDetails.expiryDate);
      const today = new Date();
      const timeDifference = expiryDate - today;
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setDaysRemaining(daysRemaining);

      if (daysRemaining <= 7) {
        setColor("#ff2929");
      } else if (daysRemaining <= 10) {
        setColor("#ff8629");
      } else if (daysRemaining <= 15) {
        setColor("#ffbb29");
      }
    }
  }, [userDetails]);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const open = Boolean(anchorEl);
  //   const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      {daysRemaining !== null && daysRemaining <= 15 && (
        <Chip
          label={`Expires in ${daysRemaining} days`}
          //   onClick={handleClick}
          sx={{
            color: color,
            border: `1px solid ${color}`,
            borderRadius: "16px",
            padding: "5px 12px",
            background: "transparent",
            // cursor: "pointer",
            // "&:hover": {
            //   opacity: 0.8,
            // },
          }}
        />
      )}

      {/* Popover */}
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}>
        <Box sx={{ position: "relative" }}>
          <Arrow />
          <Paper elevation={2} sx={{ p: 2, width: "300px", mt: "10px", backgroundColor: "background.paper" }}>
            <Box className="v-center space-between">
              <Box
                bgcolor={color}
                color="#fff"
                px={2}
                py={1}
                borderRadius="16px"
                sx={{
                  boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}>
                <Typography variant="body2">Expires in {daysRemaining} days</Typography>
              </Box>
              <Button variant="text" color="primary">
                Upgrade
              </Button>
            </Box>
            <Typography variant="body2" align="center" mt={2}>
              If you want to learn more about the product and features, please reach out to{" "}
              <a href="mailto:help@viduthalai.com">help@viduthalai.com</a> or get a{" "}
              <a href="https://your-demo-link.com" target="_blank" rel="noopener noreferrer">
                free 1-1 demo
              </a>{" "}
              now.
            </Typography>
          </Paper>
        </Box>
      </Popover> */}
    </Box>
  );
};

export default ExpiryNotification;
