import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Box } from "@mui/material";
import { originUrl } from "../../_helpers/utils";

export default function Notifications() {
  return (
    <Box>
      <SwaggerUI url={`${originUrl}/notification.json`} />
    </Box>
  );
}
