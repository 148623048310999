import { DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, Card } from "@mui/material";

const ConfirmModel = ({ confirmModel, setConfirmModel, returnValue }) => {
  const handleClose = () => {
    setConfirmModel(false);
    returnValue({ logedInHere: false, confirmModel: false });
  };
  const logedInHere = () => {
    setConfirmModel(false);
    returnValue({ logedInHere: true, confirmModel: false });
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={confirmModel}
      onClose={handleClose}>
      <Card>
        <DialogTitle>{"Terminate Active Login Session ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{"There is already an active user session. You want to terminate it?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ backgroundColor: "#AAAAAA" }} onClick={handleClose}>
            {"Cancel"}
          </Button>
          <Button variant="contained" onClick={logedInHere}>
            {"Confirm"}
          </Button>
        </DialogActions>
      </Card>
    </Backdrop>
  );
};

export default ConfirmModel;
