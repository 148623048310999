import { combineReducers } from "redux";

import toastSlice from "./toastSlice";
import authSlice from "./authSlice";
const rootReducer = combineReducers({
  toast: toastSlice,
  auth: authSlice,
});

export default rootReducer;
